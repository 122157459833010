<template>
  <div class="system-logs">
    <template v-if="!loading">
      <AdminSubMenu :sub-menu="subMenu">
        {{ $t('System Logs') }}
      </AdminSubMenu>
      <router-view />
    </template>
  </div>
</template>

<i18n>
{
  "en": {
    "System Logs": "System Logs",
    "Simulation": "Simulation",
    "Housekeeping": "Housekeeping",
    "User": "User",
  },
  "ja": {
    "System Logs": "システムログ",
    "Simulation": "シミュレーション",
    "Housekeeping": "清掃",
    "User": "ユーザー",
  }
}
</i18n>

<script>
import AdminSubMenu from '@/components/AdminSubMenu';

export default {
  name: 'SystemLogs',
  components: { AdminSubMenu },
  computed: {
    subMenu() {
      return [
        {
          name: 'system-logs-simulation',
          title: this.$t('Simulation'),
        },
        {
          name: 'system-logs-housekeeping',
          title: this.$t('Housekeeping'),
        },
        {
          name: 'system-logs-user',
          title: this.$t('User'),
        },
        {
          name: 'system-logs-neppan',
          title: 'Neppan',
        },
        {
          name: 'system-logs-anshin',
          title: 'Anshin',
        },
      ];
    },
    childrenRoutes() {
      return ['system-logs-simulation', 'system-logs-housekeeping', 'system-logs-user'];
    },
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep .admin-submenu-section {
  padding-top: 4.7rem;

  h1 {
    margin-bottom: 20px !important;
  }
}
</style>
